import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Spinner } from 'react-bootstrap';
import fireApp, { fireStoreDB } from '../../Hooks/fire-app';

import CustomCK from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useAuth } from '../../Hooks/useAuth';
const editorConfiguration = {
    toolbar: [
        'heading',
        '|',
        'alignment',
        'fontSize',
        'fontColor',
        'bold',
        'italic',
        'subscript',
        'superscript',
        'underline',
        'horizontalLine',
        'link',
        '|',
        'outdent',
        'indent',
        'numberedList',
        'bulletedList',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo'
    ],
    image: {
        toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            'toggleImageCaption'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
        ]
    },

};
const blogsRef = fireStoreDB.collection("notes");

class MyUploadAdapter {
    constructor(loader, blogSlug) {
        this.loader = loader;
        this.blogSlug = blogSlug;

    }

    upload() {
        return this.loader.file.then(
            file =>
                new Promise((resolve, reject) => {
                    console.log("log", file.name)
                    let storage = fireApp.storage().ref();
                    let blogRef = storage.child(`blogs/${this.blogSlug}/${file.name}`)
                    blogRef
                        .put(file).then((snapshot) => {
                            snapshot.ref.getDownloadURL().then(res => {
                                resolve({
                                    default: res
                                });
                            })
                        })
                })
        );
    }
}



const CustomEditorBuild = () => {
    const {hasAccess} = useAuth()

    const blogText = `<h1>Hello!</h1>`;
    const [blog, setBlog] = useState({})
    const [loading, setLoading] = useState(true)

    const [blogContent, setBlogContent] = useState(blogText);
    const { slug } = useParams();
    const blogSlug = slug;

    const { register, handleSubmit, watch, errors } = useForm();
    useEffect(() => {
        blogsRef.doc(slug).get().then(response => {
            console.log(response.data());
            setBlog(response.data());
            setLoading(false);
        })
    }, [])

    const saveBlog = () => {
        const updatedBlog = blog;
        updatedBlog.lastUpdated = new Date();
        blogsRef.doc(slug).set(blog).then(res => {
            console.log("done");
        })
    }
    return (
        <>
            {loading ? <Spinner animation="grow" /> :
                <div className="container-fluid">
                    <div className="d-flex justify-content-between">
                        <h1 className="font-weight-bold h3 text-center mb-4 text-primary">{blog.title} </h1>
                        <div>
                            {hasAccess && <Button onClick={saveBlog} size="sm">Save</Button>}
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <CKEditor
                                editor={CustomCK}
                                config={editorConfiguration}
                                data={blog.content}

                                onReady={editor => {
                                    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                        return new MyUploadAdapter(loader, blogSlug);
                                    };
                                }}

                                onInit={editor => {
                                    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                        return new MyUploadAdapter(loader);
                                    };
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setBlog({ ...blog, content: data })
                                    // console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />

                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default CustomEditorBuild;