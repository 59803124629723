import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { notesCollectionRef } from "../../Hooks/fire-app";
import { useNotes } from "../../Hooks/useNotes";
import moment from 'moment'
import { timeStampToDate } from "../../utilities";

const AllNotes = () => {
    const { notes, loading } = useNotes()
    return (
        <>
            {/* <div className="jumbotron text-center">
                <h4>All Notes</h4>
            </div> */}
            <div className="container">
                {/* <button onClick={()=> console.log(notes)} className="btn btn-danger">Log</button> */}
                <div className="row">
                    {loading ? <Spinner /> : notes && notes.map(item => <NoteCard data={item} key={item.key} />)}
                </div>
            </div>
        </>
    );
};

export default AllNotes;


const NoteCard = ({ data }) => {
    const deleteNote = (id) => {
        notesCollectionRef.doc(id).delete();
    }
    const updatePrivacy = (id) => {
        notesCollectionRef.doc(id).update({
            private: !data.private
        })
    }
    return (
        <>
            {data.private ? <></> : <div className="col-md-6 mb-4">
                <div className="card p-4">
                    <h5>{data.title} </h5>
                    <div>
                    <small className="font-weight-bold">{timeStampToDate(data.lastUpdated)} </small> |
                    <small>  {timeStampToDate(data.createdAt)} </small>  
                    {/* <small>{moment(data.lastUpdated, "x").format("DD MMM YYYY hh:mm a")} </small> |
                    <small>  {moment(data.createdAt, "x").format("DD MMM YYYY hh:mm a")} </small>   */}
                    </div>
                    <div className="mt-3">
                        <Link to={`/n/${data.slug}`} className="btn btn-primary btn-sm">View</Link>
                        <button onClick={() => deleteNote(data.id)} className="btn btn-danger btn-sm ml-3">Delete</button>
                        <button onClick={() => updatePrivacy(data.id)} className="btn btn-warning btn-sm ml-3">{data.private ? "Make Public" : "Make Private"} </button>
                    </div>
                </div>
            </div>}
        </>
    )
}