import React, { useState } from 'react';
import "./styles.scss"
import { useForm } from "react-hook-form";
import slugify from "slugify";
import { useHistory } from 'react-router-dom';
import { useNotes } from '../../Hooks/useNotes';
import { notesCollectionRef } from '../../Hooks/fire-app';



const AddNewNote = () => {
    const { notes, loading } = useNotes();
    const [similarBlog, setSimilarBlog] = useState(null);

    const { register, handleSubmit, watch, errors } = useForm();
    const blogObject = {
        title: "",
        slug: "",
        content: ``,
        createdAt: new Date(),
        lastUpdated: new Date(),
        private: false
    }
    const [blogSlug, setBlogSlug] = useState(null);
    const history = useHistory();
    const onTitleChange = (e) => {
// const slu = e.target.value
        const slu = slugify(e.target.value, {
            replacement: '-',
            remove: undefined,
            lower: true,
            strict: true,
            locale: 'vi'       // language code of the locale to use
        })
        let sameBlog = null;
        sameBlog = notes.find(item => item.slug === slu);
        setSimilarBlog(sameBlog)
        setBlogSlug(slu)
    }
    const onSubmit = (data, e) => {

        const updatedBlog = { ...blogObject, ...data, slug: blogSlug }
        notesCollectionRef.doc(blogSlug).set(updatedBlog)
        history.push("/n/" + blogSlug)
    }
    return (
        <>
            <h3 className="text-center mt-4">Add New Note</h3>
            <h5 className="text-center">{similarBlog ? <span className="text-danger">Same blog exists</span> : <span className="text-primary"></span>}</h5>
            {/* <button onClick={() => console.table(blogs)} className={`btn btn-primary ${similarBlog && "disabled"}`} >Log</button> */}
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row">
                                <InputWrapper col="6" label="Title">
                                    <input {...register('title')} onChange={onTitleChange} type="text" name="title" className="form-control"  />
                                </InputWrapper>
                                <InputWrapper col="6" label="URL Slug">
                                    <input {...register('slug')} type="text" name="slug" defaultValue={blogSlug} className="form-control"  />
                                </InputWrapper>
                                <div className="col-12 text-center">
                                    {similarBlog ? <button disabled className="btn btn-secondary" type="submit" >Sorry!</button> : <button className="btn btn-primary" type="submit" >Next!</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddNewNote;

const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + col + " " + addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{label} </span>
            </div>
            {children}
        </div>
    );
};
