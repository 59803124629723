import { Layout, Menu } from 'antd';
import {  UserOutlined, UnorderedListOutlined,AppstoreAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import "./AntdMenu.scss"
// import { useAuth } from '../../Hooks/useAuth';
const { Header, Content, Footer, Sider } = Layout;


const AntdMenu = () => {
    // const { hasAccess } = useAuth();
    const showMenu = true;
    
    return (
        <>
            <Sider
                title="Swajan"
                style={{
                    height: '100vh',
                    position: 'fixed',
                    zIndex: "20"
                }}
                breakpoint="sm"
                collapsedWidth="0"
            >
                <div className="logo" />
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[showMenu?  "1": "5" ]}>
                    <h3 className="text-white text-center mt-3">স্বজন</h3>
                    <hr />
                    {showMenu &&
                        <Menu.Item key="1" icon={<UnorderedListOutlined />}>
                            <Link className="mt-2" to="/">Notes</Link>
                        </Menu.Item>}
                    {showMenu &&
                        <Menu.Item key="2" icon={<AppstoreAddOutlined />}>
                            <Link className="mt-2" to="/add">Add</Link>
                        </Menu.Item>}
             
                
               
                    <Menu.Item key="6" icon={<UserOutlined />}>
                        <Link className="mt-2" to="/login"> Login</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
            <div className="d-none d-md-block">
            <Sider
                title="Prottoy"
                style={{
                    height: '100vh',
                }}
                breakpoint="sm"
                collapsedWidth="0"
            >
                <div className="logo" />
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[showMenu?  "1": "5" ]}>
                    <h3 className="text-white text-center mt-3">Prottoy</h3>
                    <hr />
                    {showMenu &&
                        <Menu.Item key="1" icon={<AppstoreAddOutlined />}>
                            <Link className="mt-2" to="/">All Notes</Link>
                        </Menu.Item>}
                    {showMenu &&
                        <Menu.Item key="2" icon={<AppstoreAddOutlined />}>
                            <Link className="mt-2" to="/add/blog">add note</Link>
                        </Menu.Item>}
           
                </Menu>
            </Sider>
            </div>
            
       
        </>
    );
};

export default AntdMenu;