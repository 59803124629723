import { Route, Switch } from 'react-router-dom';
import { useAuth } from '../Hooks/useAuth';
import AccessRestricted from './AccessRestricted';
import AddNewNote from './AddNewNote/AddNewNote';
import AllNotes from './AllNotes/AllNotes';
import CustomEditorBuild from './EditNote/CustomEditorBuild';
import LoginPage from './LoginPage/LoginPage';

const Main = () => {
    const { hasAccess } = useAuth();
    const giveAccess = hasAccess;
    return (
        <>
            <Switch>
                {giveAccess && <Route exact path="/">
                    <AllNotes />
                </Route>}
                {giveAccess && <Route exact path="/add">
                    <AddNewNote />
                </Route>}
                <Route exact path="/n/:slug">
                    <CustomEditorBuild />
                </Route>
                <Route exact path="/login">
                    <LoginPage />
                </Route>
                <Route exact path="*">
                    <AccessRestricted />
                </Route>
            </Switch>
        </>
    );
};

export default Main;