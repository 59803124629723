import { Calendar } from 'antd';
import Main from './pages/Main';
import { BrowserRouter as Router } from "react-router-dom"
import SiteLayout from './components/AntdLayout/AntdLayout';
import { NotesContextProvider } from './Hooks/useNotes';
import { AuthContextProvider } from './Hooks/useAuth';
import "./styles.css"
function App() {
  function onPanelChange(value, mode) {
    console.log(value.format('YYYY-MM-DD'), mode);
  }
  return (
    <>
      <AuthContextProvider>
        <NotesContextProvider>
          <Router>
            <SiteLayout>
              <Main />
            </SiteLayout>
          </Router>
        </NotesContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
