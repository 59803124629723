
const AccessRestricted = () => {
    return (
        <>
            <div className="jumbotron text-center">
                <h2><b>403</b> | Access Restricted</h2>
                <p className="mt-4"><b>Contact: </b> <u> <a className="text-primary" href="mailto:contact@swajan.io">contact@swajan.io</a></u> </p>
            </div>
        </>
    );
};

export default AccessRestricted;