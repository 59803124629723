import { Layout, Menu } from 'antd';
import AntdMenu from "./AntdMenu"
const { Header, Content, Footer, Sider } = Layout;
const SiteLayout = ({ children }) => {
  return (
    <>
      <Layout>
        <AntdMenu/>
        
        <Layout>
          {/* <Header className="site-layout-sub-header-background" style={{ padding: 0 }} /> */}
          <Content style={{ margin: '24px 0px 0' }}>
            {/* <Content > */}
            <div className="site-layout-background" style={{  minHeight: 360 }}>
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>&copy; Swajan | All rights reserved </Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default SiteLayout;