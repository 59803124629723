import React, { createContext, useContext, useState } from "react";
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { fireStoreDB, websiteDataRef } from "./fire-app";


const NotesContext = createContext();
const notesRef = fireStoreDB.collection("notes");

export const NotesContextProvider = ({ children }) => {
    const notesManager = NotesManager();
    return (
        <NotesContext.Provider value={notesManager}>
            { children}
        </NotesContext.Provider >
    )
}
export const useNotes = () => useContext(NotesContext);

const NotesManager = () => {

    const [notes, loading, error] = useCollectionData(notesRef.orderBy('lastUpdated', 'desc'), { idField: "id" });
    


    return {
        notes, loading, error
    }
}